import { makeAutoObservable, runInAction } from "mobx";

import { Destination } from "../types/organization/visits";
import { Host } from "../types/organization/host";

import { destinationsApi } from "../api/organization-admin/destinations";
import { hostsApi } from "../api/organization-admin/hosts";

class VisitsStore {
  constructor() {
    makeAutoObservable(this);
  }

  destinations: Destination[] = [];

  hosts: Host[] = [];

  fetchDestinations = async (page, perPage) => {
    const results = await destinationsApi.getDestinations(page, perPage);

    runInAction(() => {
      this.destinations = results.resources;
    });
  };

  fetchHosts = async (page, perPage) => {
    const results = await hostsApi.getHosts(page, perPage);

    runInAction(() => {
      this.hosts = results.resources;
    });
  };
}

export default VisitsStore;
