import { makeAutoObservable } from "mobx";

class AuthStore {
  constructor() {
    makeAutoObservable(this);
  }

  token: string | null = null;

  init = () => {
    const token = localStorage.getItem("token");
    if (token && !this.token) {
      this.token = token;
    }
  };

  setToken = (token: string) => {
    this.token = token;

    localStorage.setItem("token", token);
  };

  clearToken = () => {
    this.token = null;

    localStorage.removeItem("token");
  };
}

export default AuthStore;
