import axios from "axios";

import {
  attachAuthTokenInterceptor,
  attachSiteTokenInterceptor,
  attachOrgTokenInterceptor,
} from "../interceptors/Auth";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(attachAuthTokenInterceptor);
api.interceptors.request.use(attachSiteTokenInterceptor);
api.interceptors.request.use(attachOrgTokenInterceptor);

export default api;
