import store from "../../store/ApplicationStore";
import { AxiosRequestConfig, AxiosResponse } from "axios";

const errorHandlers = {
  401: (error: any) => {
    throw error;
  },
};

const attachAuthTokenInterceptor = (config: AxiosRequestConfig) => {
  const {
    auth: { token },
  } = store;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
};

const attachSiteTokenInterceptor = (config: AxiosRequestConfig) => {
  const {
    site: { siteToken },
  } = store;

  if (siteToken) {
    config.headers["s-site-token"] = siteToken;
  }

  return config;
};

const attachOrgTokenInterceptor = (config: AxiosRequestConfig) => {
  const {
    organization: { activeOrganizationToken },
  } = store;

  if (activeOrganizationToken) {
    config.headers["s-organization-token"] = activeOrganizationToken;
  }

  return config;
};

const onResponseInterceptor = (response: AxiosResponse) => {
  return response;
};

const onErrorInterceptor = (error: any) => {
  const errorHandler = errorHandlers[error?.response?.status];

  if (errorHandler) {
    return errorHandler(error);
  }

  throw error;
};

export {
  onResponseInterceptor,
  onErrorInterceptor,
  attachAuthTokenInterceptor,
  attachSiteTokenInterceptor,
  attachOrgTokenInterceptor,
};
