import { makeAutoObservable } from "mobx";
import { Site } from "../types/organization/site";

class OrganizationStore {
  constructor() {
    makeAutoObservable(this);
    this.activeOrganizationToken = localStorage.getItem("activeOrganizationToken") ?? null;
  }

  activeOrganizationToken: string | null = null;

  init = () => {
    const activeOrganizationToken = localStorage.getItem("activeOrganizationToken");
    if (activeOrganizationToken && !this.activeOrganizationToken) {
      this.activeOrganizationToken = activeOrganizationToken;
    }
  };

  setActiveOrganizationToken = (activeOrganizationToken: string) => {
    this.activeOrganizationToken = activeOrganizationToken;

    localStorage.setItem("activeOrganizationToken", activeOrganizationToken);
  };

  clearActiveOrganizationToken = () => {
    this.activeOrganizationToken = null;

    localStorage.removeItem("activeOrganizationToken");
  };
}

export default OrganizationStore;
