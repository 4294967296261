import { useEffect, useMemo, useState } from "react";
import type { FC, ElementType } from "react";
import { useLocation, matchPath } from "react-router-dom";

import { Box, Divider, Drawer, IconButton, List } from "@material-ui/core";
import { Scrollbar } from "../../components/atoms/Scrollbar";
import { SidebarItem } from "../../components/molecules/SidebarItem";
import { Logo } from "../../components/atoms/Logo";

import { ChevronLeft as ChevronLeftIcon } from "../../icons/chevron-left";
import { ChevronRight as ChevronRightIcon } from "../../icons/chevron-right";

import { getRoutes } from "src/constants/routes";
interface SidebarProps {
  onPin: () => void;
  pinned: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

export const Sidebar: FC<SidebarProps> = (props) => {
  const { onPin, pinned } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState("");
  const [hovered, setHovered] = useState(false);

  const routes = useMemo(() => getRoutes(), []);

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    routes.forEach((route) => {
      if (route.subRoutes) {
        for (let index = 0; index < route.subRoutes.length; index++) {
          const active = matchPath({ path: route.subRoutes[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(route);
            setActiveHref(route.subRoutes[index].href);
            setOpenedItem(route);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: route.href, end: true }, pathname);

        if (active) {
          setActiveItem(route);
          setOpenedItem(route);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      open
      // sx={{ zIndex: 1000 }}
      variant="permanent"
      PaperProps={{
        onMouseOver: () => {
          setHovered(true);
        },
        onMouseLeave: () => {
          setHovered(false);
        },
        sx: {
          backgroundColor: "background.paper",
          height: "calc(100% - 64px)",
          overflowX: "hidden",
          top: 64,
          zIndex: 1,
          transition: "width 250ms ease-in-out",
          width: pinned ? 270 : 73,
          "& .simplebar-content": {
            height: "100%",
          },
          "&:hover": {
            width: 270,
            "& span, p": {
              display: "flex",
            },
          },
        },
      }}
    >
      <Scrollbar
        style={{
          display: "flex",
          flex: 1,
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            p: 2,
          }}
        >
          <List disablePadding>
            {routes.map((route) => (
              <SidebarItem
                active={activeItem?.title === route.title}
                activeHref={activeHref}
                key={route.title}
                onOpen={() => handleOpenItem(route)}
                open={openedItem?.title === route.title && (hovered || pinned)}
                pinned={pinned}
                {...route}
              />
            ))}
          </List>
          <Box sx={{ flexGrow: 1 }} />
          <Divider />
          <Box
            sx={{
              pt: 1,
              mr: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <IconButton onClick={onPin}>
              {pinned ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
            <Logo height={17} width={70} />
          </Box>
        </Box>
      </Scrollbar>
    </Drawer>
  );
};
