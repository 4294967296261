import { Organization } from "src/types/organization/organization";
import { VisitFinishSetting } from "src/types/organization/visits";
import { Site } from "../../types/organization/site";
import api from "../clients/api";

class OrganizationApi {
  async fetchOrganizationSites(): Promise<Site[]> {
    const { data } = await api.get(
      "/organization/sites"
      // {
      //   headers: { "s-organization-token": orgToken },
      // }
    );

    return data;
  }

  async updateOrganizationGdprClause(gdprClause: string): Promise<Organization> {
    const { data } = await api.patch<Organization>("/organization/gdpr", {
      gdprClause,
    });
    return data;
  }

  async fetchOrganizationDetails(): Promise<Organization> {
    const { data } = await api.get<Organization>("/organization");
    return data;
  }

  async updateDefaultVisitFinishSetting(
    visitFinishSetting: VisitFinishSetting
  ): Promise<Organization> {
    const { data } = await api.patch<Organization>("/organization/visit-finish-setting", {
      visitFinishSetting,
    });
    return data;
  }

  async updateOrganizationLogo(logoFile: File): Promise<Organization> {
    const formData = new FormData();
    formData.append("logo", logoFile);

    const { data } = await api.patch<Organization>("/organization/logo", formData);
    return data;
  }

  async removeOrganizationLogo(): Promise<Organization> {
    const { data } = await api.delete<Organization>("/organization/logo");
    return data;
  }
}

export const orgApi = new OrganizationApi();
