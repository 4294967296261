import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      common: {
        noResults: "No results found",
        availableSoon: "Available soon",
        bulkActions: "Bulk actions",
        incorrectFileType: "Incorrect file type",
        maxFilesNumberError: "Number of files exceeded the {{limit}} files limit",
        statusVariants: {
          created: "Created",
          cancelled: "Cancelled",
          started: "Started",
          finished: "Finished",
        },
        statusVariantVisitor: {
          invited: "Invited",
          cancelled: "Cancelled",
          arrived: "Arrived",
          left: "Left",
        },
        pagination: {
          page: "Page",
          of: "of",
        },
        button: {
          reset: "Reset all filters",
          nextStep: "Next step",
          back: "Back",
          confirm: "Confirm",
          save: "Save",
          delete: "Delete",
          downloadTemplate: "Download template",
          cancel: "Cancel",
          add: "Add",
          edit: "Edit",
          invite: "Invite",
          remove: "Remove",
          close: "Close",
        },
        status: {
          created: "Created",
          cancelled: "Cancelled",
          started: "Started",
          finished: "Finished",
        },
        upload: "Upload",
        selectImages: "Select images",
        error: "Error",
        step: "Step",
        yes: "Yes",
        no: "No",
        search: "Search",
        seeDetails: "See details",
        information: "Information",
        popup: {
          success: "Success",
          error: "Error",
        },
        logOut: "Log out",
        footer: {
          aboutUs: "About Us",
        },
        clickToManage: "Click to manage",
        page: "Page",
        of: "of",
        perPage: "Per page:",
        notAdded: "Not added",
        delete: "Delete",
        cancel: "Cancel",
        confirm: "Confirm",
        areYouSure: "Are you sure?",
        privacyPolicy: "Privacy policy",
        termsAndConditions: "Terms and conditions",
      },
      visits: {
        title: "Visits",
        titleVisitors: "Visitors",
        tab: "Visits | SignalOS",
        tabVisitors: "Visitors | SignalOS",
        create: "Create visit",
        download: "Download discounted tickets raport",
        table: {
          columns: {
            guests: "Guest",
            host: "Host",
            destination: "Destination",
            expectedAt: "Expected at",
            finishesAt: "Finishes at",
            category: "Category",
            state: "State",
            visitState: "Visit state",
            visitorState: "Visitor state",
          },
          cell: {
            guest: "guests",
            floor: "Floor",
          },
          destination: "Destination",
          destinations: "Destinations",
        },
        downloadDialog: {
          title: "Download discounted tickets",
          message: "Select the date range for which you want to download discounted tickets.",
          from: "From",
          to: "To",
        },
        filters: {
          destination: "Destination",
          createdFrom: "Created from",
          expectedFrom: "Expected from",
          finishesFrom: "Finishes from",
          to: "to",
          state: "State",
          category: "Category",
          moreFilters: "More filters",
          isGuestHandicapped: "Is guest handicapped",
          isGuestPregnant: "Is guest pregnant",
          hasAccessToParking: "Has access to parking",
          visitState: "Visit state",
          visitorState: "Visitor state",
        },
      },
      createVisit: {
        title: "Create visit",
        tab: "Create visit | SignalOS",
        success: "Visit created successfully",
        steps: {
          1: "Visitor details",
          2: "Dates info",
          3: "Host info",
          4: "Destination info",
          5: "Additional information",
          6: "Confirmation",
        },
        1: {
          firstName: "First name",
          lastName: "Last name",
          language: "Language",
          additionalInformation: "Additional information",
          pl: "Polish",
          en: "English",
        },
        2: {
          expectedAt: "Expected at",
          finishesAt: "Finishes at",
        },
        4: {
          destination: "Destination",
          noAssignedDest:
            "The selected host has no assigned destinations. First, assign a destination!",
        },
        5: {
          handicappedTitle: "Is guest handicapped?",
          handicappedContent: "Check if the guest is handicapped.",
          pregnantTitle: "Is guest pregnant?",
          pregnantContent: "Check if the guest is pregnant.",
          parkingTitle: "Does the guest have access to the car park?",
          parkingContent: "Check if the guest is to have access to the parking lot.",
          hasPetTitle: "Is guest coming with pet?",
          hasPetContent: "Check if the guest is coming with pet.",
          description: "Additional notes",
          time_ends: "The booking time has expired",
          visitors_left: "Leaving the building",
          visitFinishSetting: "How to end the visit",
          categoryOfVisit: "Category of the visit",
          attachments: "Attachments",
          browseOrDrop: "Browse or drop file(s) to upload",
          eachFileShould:
            "Each file should not exceed 15 MB in size. Supported formats: .pdf, .jpeg, .png.",
        },
        validation: {
          firstName: "First name is required",
          lastName: "Last name is required",
          email: "Email is required",
          expectedAt: "Expected at date is required",
          dateType: "It doesn't look like a date. Click on the calendar and select a date",
          finishesAt: "The end of the meeting must be later than the beginning",
          finishesAtReq: "Finishes at is required",
          destination: "Destination is required",
          required: "This field is required",
          incorrectFileType: "Incorrect file type",
        },
      },
      visit: {
        title: "Visit",
        back: "Visits",
        information: {
          cardTitle: "Visitors Information",
          table: {
            guest: "Guest",
            state: "State",
            handicapped: "Is handicapped",
            pregnant: "Is pregnant",
            parking: "Public parking - license plates",
            ticket: "Parking ticket status",
            platesNotAdded: "Plates not added",
            description: "Additional notes",
          },
        },
        attachments: {
          title: "Attachments",
          name: "Name",
          downloadSelected: "Download selected",
          deleteSelected: "Delete selected",
          dialog: {
            add: {
              title: "Add attachment",
              browseOrDropFile: "Browse or drop file(s) to upload",
              eachFileShouldNotExceed:
                "Each file should not exceed 15 MB in size. Supported formats: .pdf, .jpeg, .png.",
              success: "Successfully added attachment(s)",
              warning: "Adding some attachments failed",
              failed: "Failed to add attachment(s)",
            },
            delete: {
              title: "Delete attachment",
              description:
                "Are you sure you want to delete this attachment(s)? This can't be undone.",
              deleteAttachment: "Delete attachment",
              success: "Successfully deleted attachment(s)",
              warning: "Deleting some attachments failed",
              failed: "Failed to delete attachment(s)",
            },
          },
        },
        destination: {
          cardTitle: "Destination info",
          name: "Name",
          floor: "Floor",
          directionTip: "Direction tip",
          description: "Description",
        },
        reason: {
          title: "Reason of the visit",
          category: "Category",
        },
        host: {
          cardTitle: "Host info",
          phoneNumber: "Phone number",
          canInviteToParking: "Can invite to parking",
        },
        status: {
          cardTitle: "State",
          cancelled: "Visit cancelled successfully",
          finished: "Visit finished successfully",
        },
        finish: {
          title: "Visit finish reason",
          manual: "Manual",
          automatic_visitors_left_building: "Visitor left the building",
          automatic_time_finished: "Visiting time is over",
        },
        hours: {
          cardTitle: "Hours",
          createdAt: "Created at",
          expectedAt: "Expected at",
          arrivedAt: "Arrived at",
          finishesAt: "Finishes at",
        },
        modal: {
          apply: "Pay for the parking ticket",
          ticket: "Ticket number",
          submitTicket: "Submit ticket",
          pay: "Pay",
          success: "Ticket paid successfully",
          validation: {
            required: "Ticket number is required",
          },
        },
        timeline: {
          cardTitle: "Authorizations",
          noLogs: "THERE ARE NO LOGS YET.",
          device: "Device ID",
          entrance: "Entrance ID",
          finished: "Finished",
          in: "In",
          out: "Out",
        },
        extendVisitDialog: {
          title: "Extend visit",
          date: "New finish date",
          extendSuccess: "Visit extended successfully",
          prolongError: "Visit extension in progress",
        },
      },
      hosts: {
        title: "Hosts",
        tab: "Hosts | SignalOS",
        inviteHost: "Invite host",
        table: {
          name: "Name",
          phone: "Phone number",
          organization: "Organization",
          allowedParking: "Allowed invite to parking",
          destinations: "Destinations",
          floor: "floor",
          setDestinations: "Set destinations",
          discountTemplates: "Discount templates",
          discounts: "Discounts",
          switchAllowToInvite: "Switch allow to invite to parking to yes",
          billingGroup: "Billing group",
          setBillingGroup: "Set billing group",
          destinationGroup: "Destination group",
          setDestinationGroup: "Set destination group",
        },
        modal: {
          title: "Choose destinations for that host",
          title2: "Choose billing group that host should be added to",
          title3: "Choose destination group for that host",
          thereIsNoDiscounts: "There is no discount to assign",
        },
        dialogs: {
          invite: {
            title: "Invite host",
            success: "Host invited successfully",
            email: "Email",
            firstName: "First name",
            lastName: "Last name",
            phoneNumber: "Phone number",
            validation: {
              email: "This field must be a valid email address",
              required: "This field is required",
            },
          },
          remove: {
            title: "Delete host",
            message: "Are you sure you want to delete this host?",
            success: "Host deleted successfully",
          },
        },
      },
      host: {
        back: "Back",
        firstName: "First name",
        lastName: "Last name",
        phoneNumber: "Phone number",
        allowedParking: "Allow invite to parking",
        allowedDestinations: "Allowed destinations",
        floor: "Floor",
        generalInformation: "General information",
        searchEmail: "Search by email",
        information: "Information",
        setDestination: "Set destinations",
        successUpdateDestination: "Successfully updated destinations",
        discount: {
          discount: "Discount",
          cardTitle: "Host parking discounts (not from group)",
          assignDiscount: "Assign discount",
          chooseDiscounts: "Choose discounts",
          thereIsNoDiscounts: "There is no discounts assigned",
          canInviteToParking: "Can invite",
          history: "Discount history",
          ticketNumber: "Ticket number",
          ticketTransactionNumber: "Ticket transaction number",
          status: "Status",
          paidAt: "Paid at",
        },
        billingGroup: {
          cardTitle: "Billing group and its discounts",
          setBillingGroup: "Set billing group",
          discount: "Discount",
          noGroup: "The host does not belong to any group",
        },
        destinationGroup: {
          cardTitle: "Destination group",
          setGroup: "Set destination group",
          noGroup: "The host does not belong to any group",
          name: "Name",
        },
        filterByDestinationGroup: {
          title: "Search hosts by destination group",
          destinations: "Destinations groups",
          chip: "Selected destination group: ",
        },
        filterByBillingGroup: {
          title: "Search hosts by billing group",
          destinations: "Billing groups",
          chip: "Selected billing group: ",
        },
      },
      destinations: {
        title: "Destinations",
        tab: "Destinations | SignalOS",
        create: "Create destination",
        table: {
          name: "Name",
          floor: "Floor",
          maxCapacity: "Max capacity",
          description: "Description",
          acs: "Access control profiles",
          setAccess: "Set access control profiles",
        },
        modalDelete: {
          title: "Delete destination",
          message: "Are you sure, you want to remove that destination?",
        },
        modalAssign: {
          title: "Assign access control profile",
        },
      },
      destination: {
        back: "Destinations",
        info: {
          cardTitle: "General information",
          name: "Name",
          floor: "Floor",
          description: "Description",
          directionTip: "Direction tip",
          maxCap: "Max capacity",
          accessBefore: "Access before visit in minutes",
          accessAfter: "Access after visit in minutes",
        },
        profiles: {
          cardTitle: "Access control profiles",
          noAccess: "No access control profiles assigned",
          profile: "Profile",
        },
      },
      createDestination: {
        title: "Create destinations",
        tab: "Create destinations | SignalOS",
        form: {
          step: "Step",
          details: "Destination details",
          confirmation: "Confirmation",
          name: "Destination name",
          nameTip: "Name of the destinations",
          floor: "Floor",
          floorTip: "The floor on which the destination is located",
          description: "Description",
          descriptionTip: "Destination description",
          direction: "Direction tip",
          directionTip: "Destination direction tip - will be visible in visit email",
          maxCapacity: "The maximum number of guests",
          maxCapacityTip: "The maximum number of guests allowed for a given destination",
          accessBefore: "Access before visit in minutes",
          accessBeforeTip:
            "Number of minutes for which a given guest can show up earlier in this destination",
          accessAfter: "Access after visit in minutes",
          accessAfterTip:
            "Number of minutes until the visitor leaves the place after completing the visit",
        },
        validation: {
          nameRequired: "Name is required",
          floorRequired: "Floor is required",
          maxCapacity: "The maximum number of guests is required",
          description: "Description is required",
          directionTip: "Direction tip is required",
          required: "Required",
          maxCapacityMinValue: "The maximum number of guests cannot be less than 1",
          accessBeforeVisitInMinutesMinValue: "Access before visit cannot be less than 0",
          accessAfterVisitInMinutesMinValue: "Access after visit cannot be less than 0",
        },
        popup: {
          destination: "Destination created",
        },
      },
      statistics: {
        title: "Statistics",
        tab: "Visits statistics | SignalOS",
        to: "to",
        from: "From",
        To: "to",
        guestInBuilding: "Guests in the building",
        guestToArrive: "Guests to arrive",
        todays: "Todays guests",
        chart: {
          cardTitle: "Days insight",
          noResult: "There are no visits these days",
          invited: "Invited",
          cancelled: "Cancelled",
          arrived: "Arrived",
          left: "Left",
          expired: "Expired",
          today: "Today",
          days7: "7 days",
        },
        today: {
          cardTitle: "Today insights",
          noResult: "There are no visits these days",
        },
      },
      acp: {
        title: "Access control profiles",
        tab: "ACP | SignalOS",
        name: "Name",
        createdAt: "Created at",
        updatedAt: "Updated at",
        noObject: "You have not been granted access control. Please contact your Facility Manager.",
      },
      billingGroups: {
        title: "Billing groups",
        tab: "Billing groups | SignalOS",
        delete: "Delete billing group",
        edit: "Edit billing group",
        table: {
          name: "Name",
        },
        create: {
          title: "Create billing group",
          required: "Name is required",
          name: "Name",
          discount: "Choose discount template for that group:",
          billingGroup: "Billing group",
          edited: "edited",
          created: "created",
          updated: "updated",
          deleted: "deleted",
          areYouSure: "Are you sure you want to delete",
          deletingGroup: "Deleting group",
          thereAreNoDiscounts: "There are no discounts to assign",
        },
        tableHistory: {
          cardTitle: "Discount codes history",
          code: "Discount code",
          name: "Discount name",
          ticket: "Parking ticket",
          ticketStatus: "Parking ticket status",
          createdAt: "Created at",
        },
        details: {
          info: "Information",
        },
        host: {
          cardTitle: "Hosts added to that group",
        },
      },
      destinationGroups: {
        title: "Destination groups",
        tab: "Destination groups | SignalOS",
        create: "Create destination group",
        delete: "Delete destination group",
        edit: "Edit destination group",
        table: {
          name: "Name",
          isDefault: "Is default",
          setAsDefault: "Set as default",
          removeFromDefault: "Remove from default",
        },
        tableDestinations: {
          cardTitle: "Assigned destinations",
        },
        dialog: {
          name: "Name",
          discount: "Choose destinations for that group:",
          destinationGroup: "Destination group",
          edited: "edited",
          created: "created",
          updated: "updated",
          deleted: "deleted",
          areYouSure: "Are you sure you want to delete",
          deletingGroup: "Deleting group",
        },
        host: {
          cardTitle: "Hosts added to that group",
        },
      },
      admins: {
        title: "Admins",
        tab: "Admin | SignalOS",
        name: "Name",
        add: "Add admin",
        successAdded: "Admin added successfully",
        friendlyName: "Friendly name",
        areYouSureYouWantRemove: "Are You sure, you want to remove admin:",
      },
      accessControlEvents: {
        title: "Access control events",
        tab: "Access control events | SignalOS",
        name: "Name",
        expectedToLeaveAt: "Scheduled departure time",
        leftAt: "Departure time",
        leftError: "Guest didn't left the building",
        email: "Email",
        host: "Host",
        reason: "Reason",
        presenceAfterVisitFinished: "Presence in building after visit finished",
      },
      organizationAdmins: {
        title: "Administrators",
        email: "Email",
        role: "Role",
        action: "Remove",
        removeDialogMessage: "Are you sure You want to remove Organization Admin ",
        removeSuccess: "User successfully removed from organization.",
        inviteSuccess: "User successfully invited to organization.",
        inviteButton: "Invite",
        cancelButton: "Cancel",
        dialogTitle: "Invite new admin to organization.",
        removeTooltipTitle: "Only organization owner can remove users.",
        inviteTooltipTitle: "Only organization owner can invite new users.",
        friendlyName: "Friendly name",
        owner: "Owner",
        admin: "Admin",
        validation: {
          inviteEmailRequired: "Email is required.",
          inviteEmailError: "Provide a valid email address.",
        },
      },
      settings: {
        title: "Settings",
        tab: "Settings | SignalOS",
        gdpr: {
          title: "GDPR clauses",
          tab: "GDPR clauses | SignalOS",
          guestTitle: "GDPR clause for guest",
          new_userTitle: "GDPR clause for user",
          new_userTitleDescription: "Clause will be used in email while inviting new user",
          guestTitleDescription: "Clause will be used in email while inviting guest",
          gdprClauseMaxText: "The GDPR clause cannot exceed 10,000 characters.",
          pl: "Polish",
          en: "English",
          noClause: "No GDPR clause",
          addTitle: "Add GDPR clause",
          editTitle: "Edit GDPR clause",
          addSuccess: "GDPR clause added successfully",
          deleteConfirm: "Are you sure you want to delete GDPR clause?",
          updateSuccess: "GDPR clause updated successfully",
          deleteSuccess: "GDPR clause deleted successfully",
          showAll: "Show all",
          plClause: "Polish GDPR clause",
          enClause: "English GDPR clause",
          clause: "GDPR clause",
          table: {
            language: "Language",
            content: "Content",
          },
        },
        default: {
          title: "Default settings",
          tab: "Default settings | SignalOS",
          visits: {
            title: "Visits",
            visitFinishSetting: "How to end the visit",
            visitFinishSettingChangeSuccess: "Visit end reason changed successfully",
            time_ends: "The booking time has expired",
            visitors_left: "Guests left the building",
          },
        },
        logo: {
          title: "Organization logo",
          tab: "Organization logo | SignalOS",
          removeLogo: "Remove logo",
          addLogo: "Add logo",
          addLogoDialogTitle: "Add logo to your organization",
          noLogo: "No logo added yet",
          description: "This logo will be used in e-mails",
          removeLogoText: "Are you sure you want to remove this logo?",
          addSuccess: "Logo added successfully",
          removeSuccess: "Logo removed successfully",
        },
      },
    },
  },
  pl: {
    translation: {
      common: {
        noResults: "Brak wyników",
        availableSoon: "Dostępne wkrótce",
        bulkActions: "Działania masowe",
        incorrectFileType: "Nieprawidłowy format pliku",
        maxFilesNumberError: "Przekroczono limit plików {{limit}}",
        statusVariants: {
          created: "Utworzona",
          cancelled: "Anulowana",
          started: "Rozpoczęta",
          finished: "Zakończona",
        },
        statusVariantVisitor: {
          invited: "Zaproszony",
          cancelled: "Anulowane",
          arrived: "Przybył",
          left: "Opuścił",
        },
        pagination: {
          page: "Strona",
          of: "z",
        },
        button: {
          reset: "Resetuj filtry",
          nextStep: "Następny krok",
          back: "Wróć",
          confirm: "Potwierdź",
          save: "Zapisz",
          delete: "Usuń",
          cancel: "Anuluj",
          downloadTemplate: "Pobierz wzór",
          add: "Dodaj",
          invite: "Zaproś",
          edit: "Edytuj",
          remove: "Usuń",
          close: "Zamknij",
        },
        status: {
          created: "Utworzona",
          cancelled: "Anulowana",
          started: "Rozpoczęta",
          finished: "Zakończona",
        },
        upload: "Wgraj",
        selectImages: "Wybierz zdjęcie",
        error: "Błąd",
        step: "Krok",
        yes: "Tak",
        no: "Nie",
        search: "Szukaj",
        seeDetails: "Zobacz szczegóły",
        information: "Informacje",
        popup: {
          success: "Sukces",
          error: "Błąd",
        },
        footer: {
          aboutUs: "O nas",
        },
        logOut: "Wyloguj",
        clickToManage: "Kliknij aby zarządzać",
        page: "Strona",
        of: "z",
        perPage: "Na stronę:",
        notAdded: "Nie dodana",
        delete: "Usuń",
        cancel: "Anuluj",
        confirm: "Potwierdź",
        areYouSure: "Czy na pewno?",
        privacyPolicy: "Polityka prywatności",
        termsAndConditions: "Regulamin",
      },
      visits: {
        title: "Wizyty",
        titleVisitors: "Goście",
        tab: "Wizyty | SignalOS",
        tabVisitors: "Goście | SignalOS",
        create: "Stwórz wizytę",
        download: "Pobierz raport rozliczonych biletów",
        table: {
          columns: {
            guests: "Goście",
            host: "Gospodarz",
            destination: "Destynacja",
            expectedAt: "Początek",
            finishesAt: "Koniec",
            category: "Kategoria",
            state: "Status",
            visitState: "Status wizyty",
            visitorState: "Status gościa",
          },
          cell: {
            guest: "gości",
            floor: "Piętro",
          },
          destination: "Destynacja",
          destinations: "Destynacji",
        },
        downloadDialog: {
          title: "Pobierz rozliczone bilety parkingowe",
          message: "Wybierz zakres dat, dla których chcesz pobrać rozliczone bilety.",
          from: "Od",
          to: "Do",
        },
        filters: {
          destination: "Destynacja",
          createdFrom: "Utworzone od",
          expectedFrom: "Oczekiwany od",
          finishesFrom: "Kończy od",
          to: "do",
          state: "Stan",
          category: "Kategoria",
          moreFilters: "Więcej filtrów",
          isGuestHandicapped: "Czy gość jest niepełnosprawny",
          isGuestPregnant: "Czy gość jest w ciąży",
          hasAccessToParking: "Dostęp do parkingu",
          visitState: "Status wizyty",
          visitorState: "Status gościa",
        },
      },
      createVisit: {
        title: "Stwórz wizytę",
        tab: "Stwórz wizytę | SignalOS",
        success: "Wizyta została utworzona pomyślnie",
        steps: {
          1: "Informacje o gościu",
          2: "Informacje o dacie",
          3: "Wybór gospodarza",
          4: "Wybór destynacji",
          5: "Dodatkowe informacje",
          6: "Potwierdzenie",
        },
        1: {
          firstName: "Imię",
          lastName: "Nazwisko",
          language: "Język",
          additionalInformation: "Dodatkowe informacje",
          pl: "Polski",
          en: "Angielski",
        },
        2: {
          expectedAt: "Początek spotkania",
          finishesAt: "Koniec spotkania",
        },
        4: {
          destination: "Destynacja",
          noAssignedDest:
            "Wybrany gospodarz nie ma przypisanych destynacji. Najpierw przypisz destynacje!",
        },
        5: {
          handicappedTitle: "Czy gość jest niepełnosprawny?",
          handicappedContent: "Zaznacz jeżeli gość jest niepełnosprawny.",
          pregnantTitle: "Czy gość jest w ciąży?",
          pregnantContent: "Zaznacz jeżeli gość jest w ciąży.",
          parkingTitle: "Czy gość powinien mieć dostęp do parkingu?",
          parkingContent: "Zaznacz jeżeli gość powinien mieć dostęp do parkingu.",
          hasPetTitle: "Czy gość przychodzi ze zwierzęciem?",
          hasPetContent: "Zaznacz jeżeli gość przychodzi ze zwierzęciem.",
          description: "Dodatkowe informacje",
          time_ends: "Czas rezerwacji upłynął",
          visitors_left: "Gość opuścił budynek",
          visitFinishSetting: "Wizyta zakończy się gdy",
          categoryOfVisit: "Kategoria wizyty",
          attachments: "Załączniki",
          browseOrDrop: "Przeglądaj lub upuść plik(i) do przesłania",
          eachFileShould:
            "Żaden plik nie powinien przekraczać 15 MB. Obsługiwane formaty: .pdf, .jpeg, .png.",
        },
        validation: {
          firstName: "Imię jest wymagane",
          lastName: "Nazwisko jest wymagane",
          email: "Email jest wymagany",
          required: "To pole jest wymagane",
          expectedAt: "Data i godzina przybycia jest wymagana",
          dateType: "To nie wygląda na date. Kliknij kalendarz i wybierz datę",
          finishesAt: "Koniec spotkania musi być później niż początek spotkania.",
          finishesAtReq: "Koniec spotkania jest wymagany",
          destination: "Wybór destynacji jest wymagany",
        },
      },
      visit: {
        title: "Wizyta",
        back: "Wizyty",
        information: {
          cardTitle: "Informacje o gościach",
          table: {
            guest: "Gość",
            state: "Status",
            handicapped: "Jest niepełnosprawny",
            pregnant: "Jest w ciąży",
            parking: "Parking publiczny - numery rejestracyjne",
            ticket: "Status biletu parkingowego",
            platesNotAdded: "Numery nie dodane",
            description: "Dodatkowe informacje",
          },
        },
        attachments: {
          title: "Załączniki",
          name: "Nazwa",
          downloadSelected: "Pobierz zaznaczone",
          deleteSelected: "Usuń zaznaczone",
          dialog: {
            add: {
              title: "Dodaj załącznik",
              browseOrDropFile: "Przeglądaj lub upuść plik(i) do przesłania",
              eachFileShouldNotExceed:
                "Żaden plik nie powinien przekraczać 15 MB. Obsługiwane formaty: .pdf, .jpeg, .png.",
              success: "Pomyślnie dodano załącznik(i)",
              warning: "Nie udało się dodać niektórych załączników",
              failed: "Nie udało się dodać załącznika(ów)",
            },
            delete: {
              title: "Usuń załącznik",
              description:
                "Czy na pewno chcesz usunąć ten załącznik(iki)? Tej operacji nie można cofnąć.",
              deleteAttachment: "Usuń załącznik",
              success: "Pomyślnie usunięto załącznik(i)",
              warning: "Nie udało się usunąć niektórych załączników",
              failed: "Nie udało się usunąć załącznika(ów)",
            },
          },
        },
        reason: {
          title: "Powód wizyty",
          category: "Kategoria",
        },
        destination: {
          cardTitle: "Informacje o destynacji",
          name: "Nazwa",
          floor: "Piętro",
          directionTip: "Wskazówki dotarcia",
          description: "Opis",
        },
        host: {
          cardTitle: "Informacje o gospodarzu",
          phoneNumber: "Numer telefonu",
          canInviteToParking: "Czy może zapraszać na parking?",
        },
        status: {
          cardTitle: "Status",
          cancelled: "Wizyta anulowana pomyślnie",
          finished: "Wizyta zakończona pomyślnie",
        },
        finish: {
          title: "Powód zakończenia wizyty",
          manual: "Manualnie zakończona",
          automatic_visitors_left_building: "Gość opuścił budynek",
          automatic_time_finished: "Czas wizyty upłynął",
        },
        hours: {
          cardTitle: "Godziny",
          createdAt: "Wizyta utworzona",
          expectedAt: "Początek wizyty",
          arrivedAt: "Przybył",
          finishesAt: "Koniec wizyty",
        },
        modal: {
          apply: "Zapłać za bilet parkingowy",
          ticket: "Number biletu",
          submitTicket: "Potwierdź numer",
          applyDiscount: "Zaaplikuj rabat",
          pay: "Zapłać",
          success: "Bilet opłacony poprawnie",
          validation: {
            required: "Numer biletu jest wymagany",
          },
        },
        timeline: {
          cardTitle: "Autoryzacje",
          noLogs: "Żadne logi nie sa jeszcze dostępne",
          device: "ID urządzenia",
          entrance: "ID wejścia",
          finished: "Zakończona",
          in: "Wejście",
          out: "Wyjście",
        },
        extendVisitDialog: {
          title: "Przedłuż rezerwacje",
          date: "Nowy koniec wizyty",
          extendSuccess: "Rezerwacja przedłużona pomyślnie",
          prolongError: "Trwa przedłużanie wizyty",
        },
      },
      hosts: {
        title: "Gospodarze",
        tab: "Gospodarze | SignalOS",
        inviteHost: "Zaproś gospodarza",
        table: {
          name: "Nazwa",
          phone: "Numer komórkowy",
          organization: "Organizacja",
          allowedParking: "Czy może zapraszać na parking",
          destinations: "Destynacje",
          floor: "piętro",
          setDestinations: "Przypisz destynacje",
          discountTemplates: "Dostępne zniżki",
          discounts: "Zniżki",
          switchAllowToInvite: "Najpierw przełącz możliwość zapraszania na parking",
          billingGroup: "Grupa rozliczeniowa",
          setBillingGroup: "Ustaw grupę",
          destinationGroup: "Grupa destynacji",
          setDestinationGroup: "Ustaw grupę",
        },
        modal: {
          title: "Wybierz destynacje dla tego gospodarza",
          title2: "Wybierz grupę rozliczeniową do której ma być przypisany gospodarz",
          title3: "Wybierz grupę destynacji do której ma być przypisany gospodarz",
          thereIsNoDiscounts: "Brak rabatów do przypisania",
        },
        dialogs: {
          invite: {
            title: "Zaproś gospodarza",
            success: "Gospodarz zaproszony pomyślnie",
            email: "Email",
            firstName: "Imię",
            lastName: "Nazwisko",
            phoneNumber: "Numer telefonu",
            validation: {
              email: "To pole musi być poprawnym adresem email",
              required: "To pole jest wymagane",
            },
          },
          remove: {
            title: "Usuń gospodarza",
            message: "Czy na pewno chcesz usunąć tego gospodarza?",
            success: "Gospodarz usunięty pomyślnie",
          },
        },
      },
      host: {
        back: "Wróć",
        firstName: "Imię",
        lastName: "Nazwisko",
        phoneNumber: "Numer telefonu",
        allowedParking: "Może zapraszać na parking",
        allowedDestinations: "Dostępne destynacje",
        floor: "Piętro",
        generalInformation: "Ogólne informacje",
        searchEmail: "Wyszukaj po mailu",
        information: "Informacje",
        setDestination: "Ustaw destynacje",
        successUpdateDestination: "Pomyślnie zaktualizowano destynacje",
        discount: {
          discount: "Zniżka",
          cardTitle: "Rabaty parkingowe (nie pochodzące z grupy)",
          assignDiscount: "Przypisz rabat",
          chooseDiscounts: "Wybierz rabaty",
          thereIsNoDiscounts: "Brak przypisanych rabatów",
          canInviteToParking: "Może zapraszać",
          history: "Historia rabatów",
          ticketNumber: "Number bilety",
          ticketTransactionNumber: "Numer transakcji",
          status: "Status",
          paidAt: "Zapłacone dnia",
        },
        billingGroup: {
          cardTitle: "Grupa rozliczeniowa i jej rabaty",
          setBillingGroup: "Ustaw grupę rozliczeniową",
          discount: "Zniżka",
          noGroup: "Gospodarz nie należy do żadnej grupy rozliczeniowej",
        },
        destinationGroup: {
          cardTitle: "Grupa destynacji",
          setGroup: "Ustaw grupę",
          noGroup: "Gospodarz nie należy do żadnej grupy destynacji",
          name: "Nazwa",
        },
        filterByDestinationGroup: {
          title: "Szukaj gospodarza po grupie destynacji",
          destinations: "Grupy destynacji",
          chip: "Wybrana grupa destynacji: ",
        },
        filterByBillingGroup: {
          title: "Szukaj gospodarza po grupie rozliczeniowej",
          destinations: "Grupy rozliczeniowe",
          chip: "Wybrana grupa rozliczeniowa: ",
        },
      },
      destinations: {
        title: "Destynacje",
        tab: "Destynacje | SignalOS",
        create: "Stwórz destynacje",
        table: {
          name: "Nazwa",
          floor: "Piętro",
          maxCapacity: "Max. pojemność",
          description: "Opis",
          acs: "Profile dostępu",
          setAccess: "Ustaw profile dostępu",
        },
        modalDelete: {
          title: "Usuń destynacje",
          message: "Czy na pewno chcesz usunąć tą destynację?",
        },
        modalAssign: {
          title: "Przypisz profile dostępu",
        },
      },
      destination: {
        back: "Destynacje",
        info: {
          cardTitle: "Ogólne informacje",
          name: "Nazwa",
          floor: "Piętro",
          description: "Opis",
          directionTip: "Wskazówki dojścia",
          maxCap: "Max. pojemność",
          accessBefore: "Dostęp przed wizytą w minutach",
          accessAfter: "Dostęp po wizycie w minutach",
        },
        profiles: {
          cardTitle: "Profile kontroli dostępu",
          noAccess: "Brak przypisanych profili",
          profile: "Profil",
        },
      },
      createDestination: {
        title: "Stwórz destynacje",
        tab: "Stwórz destynację | SignalOS",
        form: {
          step: "Krok",
          details: "Szczegóły destynacji",
          confirmation: "Potwierdzenie",
          name: "Nazwa destynacja",
          nameTip: "Wpisz nazwę destynacji którą będzie widział użytkownik",
          floor: "Piętro",
          floorTip: "Wpisz piętro na którym znajduje się destynacja",
          description: "Opis",
          descriptionTip: "Opis destynacji",
          direction: "Wskazówki dojścia",
          directionTip: "Wskazówki dojścia do danej destynacji",
          maxCapacity: "Maksymalna ilość gości",
          maxCapacityTip:
            "Maksymalna ilość gości, którzy mogą jednocześnie przebywać w danej destynacji",
          accessBefore: "Dostęp do destynacji przed wizytą w min",
          accessBeforeTip:
            "Czas w minutach w jakich gość może pojawić się wcześniej w danej destynacji",
          accessAfter: "Dostęp do destynacji po wizycie w min",
          accessAfterTip:
            "Czas w minutach w jakich gość może przebywać w danej destynacji po skończonej wizycie",
        },
        validation: {
          nameRequired: "Nazwa jest wymagana",
          floorRequired: "Piętro jest wymagane",
          maxCapacity: "Maksymalna ilość gości jest wymagana",
          description: "Opis jest wymagany",
          directionTip: "Wskazówki dojścia są wymagane",
          required: "Wymagane",
          maxCapacityMinValue: "Maksymalna ilość gości nie może byc mniejsza niż 1",
          accessBeforeVisitInMinutesMinValue:
            "Dostęp do destynacji przed wizytą nie może byc mniejszy niz 0",
          accessAfterVisitInMinutesMinValue:
            "Dostęp do destynacji po wizycie nie może byc mniejszy niz 0",
        },
        popup: {
          destination: "Destynacja utworzona",
        },
      },
      statistics: {
        title: "Statystyki",
        tab: "Statystyki wizyt | SignalOS",
        to: "do",
        from: "Od",
        To: "Do",
        guestInBuilding: "Gości w budynku",
        guestToArrive: "Gości do przybycia",
        todays: "Dzisiejsi goście",
        chart: {
          cardTitle: "Statystyki dzienne",
          noResult: "Brak wizyt w tych dniach",
          invited: "Zaproszonych",
          cancelled: "Anulowanych",
          arrived: "Przybyłych",
          left: "Zakończonych",
          expired: "Przeterminowanych",
          today: "Dzisiaj",
          days7: "7 dni",
        },
        today: {
          cardTitle: "Statystyki godzinowe",
          noResult: "Brak wizyt w tym dniu",
        },
      },
      acp: {
        title: "Profile kontroli dostępu",
        tab: "Profile kontroli dostępu | SignalOS",
        name: "Nazwa",
        createdAt: "Utworzone",
        updatedAt: "Uaktualnione",
        noObject: "Nie przyznano Ci kontroli dostępu. Prosimy o kontakt z kierownikiem obiektu.",
      },
      billingGroups: {
        title: "Grupy rozliczeniowe",
        tab: "Grupy rozliczeniowe | SignalOS",
        edit: "Edytuj grupę rozliczeniową",
        delete: "Usuń grupę rozliczeniową",
        table: {
          name: "Nazwa",
        },
        create: {
          title: "Stwórz grupę rozliczeniową",
          required: "Nazwa jest wymagana",
          name: "Nazwa",
          discount: "Wybierz rabaty parkingowe dostępne dla tej grupy:",
          billingGroup: "Grupa rozliczeniowa",
          edited: "edytowana",
          created: "stworzona",
          updated: "uaktualniona",
          deleted: "usunięta",
          areYouSure: "Czy na pewno chcesz usunąć?",
          deletingGroup: "Usuwanie grupy",
          thereAreNoDiscounts: "Brak rabatów do przypisania",
        },
        tableHistory: {
          cardTitle: "Historia użycia kodów rabatowych",
          code: "Kod rabatu",
          name: "Nazwa rabatu",
          ticket: "Bilet parkingowy",
          ticketStatus: "Status biletu",
          createdAt: "Utworzony",
        },
        details: {
          info: "Informacje",
        },
        host: {
          cardTitle: "Gospodarze dodani do tej grupy",
        },
      },
      destinationGroups: {
        title: "Grupy destynacji",
        tab: "Grupy destynacji | SignalOS",
        create: "Stwórz grupę destynacji",
        delete: "Usuń grupę destynacji",
        edit: "Edytuj grupę destynacji",
        table: {
          name: "Nazwa",
          isDefault: "Jest domyślna",
          setAsDefault: "Ustaw jako domyślną",
          removeFromDefault: "Usuń z domyślnych",
        },
        tableDestinations: {
          cardTitle: "Przypisane destynacje",
        },
        dialog: {
          name: "Nazwa",
          discount: "Wybierz destynacje dla tej grupy:",
          destinationGroup: "Grupa destynacji",
          edited: "edytowana",
          created: "utworzona",
          updated: "uaktualniona",
          deleted: "usunięta",
          areYouSure: "Czy na pewno chcesz usunąć?",
          deletingGroup: "Usuwanie grupy",
        },
        host: {
          cardTitle: "Gospodarze dodani do tej grupy",
        },
      },
      admins: {
        title: "Administratorzy",
        tab: "Administratorzy | SignalOS",
        name: "Nazwa",
        add: "Dodaj admina",
        successAdded: "Admin dodany poprawnie",
        friendlyName: "Przyjazna nazwa",
        areYouSureYouWantRemove: "Czy jesteś pewny, że chcesz usunąć admina:",
      },
      accessControlEvents: {
        title: "Zdarzenia kontroli dostępu",
        tab: "Zdarzenia kontroli dostępu | SignalOS",
        name: "Nazwa",
        expectedToLeaveAt: "Planowana godzina wyjścia",
        leftAt: "Godzina opuszczenia budynku",
        leftError: "Gość nie opuścił budynku",
        email: "Email",
        host: "Gospodarz",
        reason: "Powód",
        presenceAfterVisitFinished: "Obecność w budynku mimo zakończonej wizyty",
      },
      organizationAdmins: {
        title: "Administratorzy",
        email: "Email",
        role: "Rola",
        action: "Usuń",
        removeDialogMessage: "Czy na pewno chcesz usunąć administratora organizacji ",
        removeSuccess: "Użytkownik został pomyślnie usunięty z organizacji.",
        inviteSuccess: "Użytkownik pomyślnie zaproszony do organizacji.",
        inviteButton: "Zaproś",
        cancelButton: "Anuluj",
        dialogTitle: "Dodaj nowego administratora do organizacji.",
        removeTooltipTitle: "Tylko właściciel organizacji może usuwać użytkowników",
        inviteTooltipTitle: "Tylko właściciel organizacji może zapraszać nowych użytkowników",
        friendlyName: "Nazwa",
        owner: "Właściciel",
        admin: "Administrator",
        validation: {
          inviteEmailRequired: "Email jest wymagany.",
          inviteEmailError: "Wprowadź poprawny adres email.",
        },
      },
      settings: {
        title: "Ustawienia",
        tab: "Ustawienia | SignalOS",
        gdpr: {
          title: "Klauzule RODO",
          tab: "Klauzule| SignalOS",
          guestTitle: "Klauzula RODO dla gościa",
          new_userTitle: "Klauzula RODO dla użytkownika",
          new_userTitleDescription:
            "Klauzula będzie wykorzystana w e-mailu podczas zapraszania nowego użytkownika",
          guestTitleDescription:
            "Klauzula będzie wykorzystana w e-mailu podczas zapraszania gościa",
          gdprClauseMaxText: "Klauzula rodo nie może przekraczać 10,000 znaków.",
          pl: "Polski",
          en: "Angielski",
          noClause: "Brak klauzuli RODO",
          addTitle: "Dodaj klauzule RODO",
          editTitle: "Edytuj klauzule RODO",
          addSuccess: "Klauzula RODO dodana pomyślnie",
          deleteConfirm: "Czy na pewno chcesz usunąć klauzule RODO?",
          updateSuccess: "Klauzula RODO zaktualizowana pomyślnie",
          deleteSuccess: "Klauzula RODO usunięta pomyślnie",
          showAll: "Pokaż całość",
          plClause: "Klauzula RODO język polski",
          enClause: "Klauzula RODO język angielski",
          clause: "Klauzula RODO",
          table: {
            language: "Język",
            content: "Treść",
          },
        },
        default: {
          title: "Domyślne ustawienia",
          tab: "Domyślne ustawienia | SignalOS",
          visits: {
            title: "Wizyty",
            visitFinishSetting: "Sposób zakończenia wizyty",
            visitFinishSettingChangeSuccess: "Powód zakończenia wizyty zmieniony pozytywnie",
            time_ends: "Upłynięcie czasu rezerwacji",
            visitors_left: "Goście opuścili budynek",
          },
        },
        logo: {
          title: "Logo organizacji",
          tab: "Logo Organizacji | SignalOS",
          removeLogo: "Usuń logo",
          addLogo: "Dodaj logo",
          addLogoDialogTitle: "Dodaj logo do swojej organizacji",
          noLogo: "Logo nie zostało jeszcze dodane",
          description: "Logo będzie używane w e-mailach",
          removeLogoText: "Czy na pewno chcesz usunąć to logo?",
          addSuccess: "Logo dodane pomyślnie",
          removeSuccess: "Logo usunięte pomyślnie",
        },
      },
    },
  },
};

export const initializeI18n = (lng: string): void => {
  i18n.use(initReactI18next).init({
    resources,
    lng,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
};
