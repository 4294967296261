import api from "../clients/api";
import { HostResponse, Host } from "../../types/organization/host";
import { DiscountTemplate } from "../../types/organization/discount";
import { PaginatedResponse } from "../../types/responses/PaginatedResponse";
class HostsApi {
  async getHosts(page: number, perPage: number): Promise<HostResponse> {
    const { data } = await api.get<HostResponse>(`/hosts?page=${page}&perPage=${perPage}`);

    return data;
  }

  async getHost(hostId: number): Promise<Host> {
    const { data } = await api.get<Host>(`/hosts/${hostId}`);

    return data;
  }

  async createHost(
    email: string,
    firstName: string,
    lastName: string,
    phoneNumber: string
  ): Promise<void> {
    await api.post(`/hosts`, { email, firstName, lastName, phoneNumber });
  }

  async removeHost(hostId: number): Promise<void> {
    await api.delete(`/hosts/${hostId}`);
  }

  async updateHostDestinations(hostId: number, destinationIds: number[]): Promise<any> {
    const { data } = await api.put<any>(`/hosts/${hostId}/destinations`, { destinationIds });

    return data;
  }

  async assignSingleDestinationToHost(hostId: number, destinationId: number): Promise<any> {
    const { data } = await api.post<any>(`/hosts/${hostId}/destinations/${destinationId}`);

    return data;
  }

  async unassignSingleDestinationFromHost(hostId: number, destinationId: number): Promise<any> {
    const { data } = await api.delete<any>(`/hosts/${hostId}/destinations/${destinationId}`);

    return data;
  }

  async assignInviteToParkingForHost(hostId: number): Promise<any> {
    const { data } = await api.post<any>(`/hosts/${hostId}/parking`);

    return data;
  }

  async unassignInviteToParkingFromHost(hostId: number): Promise<any> {
    const { data } = await api.delete<any>(`/hosts/${hostId}/parking`);

    return data;
  }

  async getDiscountTemplates(): Promise<{ templates: DiscountTemplate[] }> {
    const { data } = await api.get<{ templates: DiscountTemplate[] }>(`/discount-templates`);

    return data;
  }

  async updateHostDiscountTemplates(
    hostId: number,
    discountTemplateIds: number[]
  ): Promise<DiscountTemplate[]> {
    const { data } = await api.put<DiscountTemplate[]>(`/hosts/${hostId}/discount-templates`, {
      discountTemplateIds,
    });

    return data;
  }

  async getHostWithSearch(
    page: number,
    perPage: number,
    email?: string,
    billingGroupId?: string,
    destinationGroupId?: string
  ): Promise<PaginatedResponse<Host>> {
    const { data } = await api.get<PaginatedResponse<Host>>(
      `/hosts/search?page=${page}&perPage=${perPage}`,
      {
        params: {
          email,
          billingGroupId,
          destinationGroupId,
        },
      }
    );

    return data;
  }

  async getHostDiscountHistory(
    hostId: string,
    page: number,
    perPage: number
  ): Promise<PaginatedResponse<Host>> {
    const { data } = await api.get<PaginatedResponse<Host>>(`/hosts/${hostId}/discount-history`, {
      params: {
        page,
        perPage,
      },
    });

    return data;
  }

  async detachDestinationsFromHost(hostId: number, destinationIds: string[]): Promise<void> {
    await api.delete<void>(`/hosts/${hostId}/destinations`, {
      params: {
        destinationIds,
      },
    });
  }
}

export const hostsApi = new HostsApi();
