import { Route } from "src/types/interfaces/Route";
import { CustomUsers as UsersIcon } from "../icons/custom-users";
import { Calendar as CalendarIcon } from "../icons/calendar";
import { User as UserIcon } from "../icons/user";
import { OfficeBuilding as OfficeBuildingIcon } from "../icons/office-building";
import { Apartment, Group, Settings } from "@material-ui/icons";
import { ExclamationOutlined as AlertIcon } from "../icons/exclamation-outlined";

export const getRoutes = (): Route[] => [
  { icon: UsersIcon, title: "visits.titleVisitors", href: "/visits" },
  { icon: CalendarIcon, title: "createVisit.title", href: "/visits/create" },
  { icon: UserIcon, title: "hosts.title", href: "/hosts" },
  { icon: Group, title: "billingGroups.title", href: "/billing-groups" },
  { icon: Apartment, title: "destinationGroups.title", href: "/destination-groups" },
  { icon: OfficeBuildingIcon, title: "destinations.title", href: "/destinations" },
  { icon: AlertIcon, title: "accessControlEvents.title", href: "/access-control-events" },
  { icon: UsersIcon, title: "organizationAdmins.title", href: "/organization-admins" },
  { icon: Settings, title: "settings.title", href: "/settings/gdpr" },
];
