import { Destination, DestinationResponse } from "../../types/organization/visits";

import api from "../clients/api";

import { ACP } from "../../types/organization/acp";

class DestinationsApi {
  async getDestinations(page: number, perPage: number): Promise<DestinationResponse> {
    const { data } = await api.get<DestinationResponse>(
      `/destinations?page=${page}&perPage=${perPage}`
    );

    return data;
  }

  async getDestination(destinationId: number): Promise<Destination> {
    const { data } = await api.get<Destination>(`/destinations/${destinationId}`);

    return data;
  }

  async updateDestination(
    destinationId: number,
    name: string,
    floor: string,
    description: string,
    macCapacity: number,
    accessBeforeVisitInMinutes: number,
    accessAfterVisitInMinutes: number
  ): Promise<Destination> {
    const { data } = await api.patch<Destination>(`/destinations/${destinationId}`, {
      name,
      floor,
      description,
      macCapacity,
      accessBeforeVisitInMinutes,
      accessAfterVisitInMinutes,
    });

    return data;
  }

  async createDestination(
    name: string,
    floor: string,
    description: string,
    maxCapacity: number,
    accessBeforeVisitInMinutes: number,
    accessAfterVisitInMinutes: number
  ): Promise<Destination> {
    const { data } = await api.post<Destination>(`/destinations`, {
      name,
      floor,
      description,
      maxCapacity,
      accessBeforeVisitInMinutes,
      accessAfterVisitInMinutes,
    });

    return data;
  }

  async deleteDestination(destinationId): Promise<Destination> {
    const { data } = await api.delete<Destination>(`/destinations/${destinationId}`);

    return data;
  }

  async getAccessControlProfiles(): Promise<ACP[]> {
    const { data } = await api.get<ACP[]>(`/access-control-profiles`);

    return data;
  }

  //!! sprawdzić czy jest uaktualnione powinno pozwolic pryzpisac i ususnac wiele
  async updateAccessControlProfilesDestination(
    destinationId: number,
    accessControlProfileIds: number[]
  ): Promise<any> {
    const { data } = await api.put<any>(`/destinations/${destinationId}/access-control-profiles`, {
      accessControlProfileIds,
    });

    return data;
  }

  async unassignSingleAccessControlProfilesFromDestination(
    destinationId: number,
    accessControlProfileId: number
  ): Promise<any> {
    const { data } = await api.delete<any>(
      `/destinations/${destinationId}/access-control-profiles/${accessControlProfileId}`
    );

    return data;
  }
}

export const destinationsApi = new DestinationsApi();
