import { makeAutoObservable } from "mobx";
import { Site } from "../types/organization/site";

class SiteStore {
  constructor() {
    makeAutoObservable(this);
    this.siteToken = localStorage.getItem("siteToken") ?? null;
  }

  siteToken: string | null = null;

  organizationSites: Site[] = [];

  init = () => {
    const siteToken = localStorage.getItem("siteToken");
    if (siteToken && !this.siteToken) {
      this.siteToken = siteToken;
    }
  };

  setSiteToken = (siteToken: string) => {
    this.siteToken = siteToken;

    localStorage.setItem("siteToken", siteToken);
  };

  clearSiteToken = () => {
    this.siteToken = null;

    localStorage.removeItem("siteToken");
  };

  setOrganizationSites = (sites: Site[]) => {
    this.organizationSites = sites;
  };
}

export default SiteStore;
