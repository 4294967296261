import VisitsStore from "./VisitsStore";
import AuthStore from "./AuthStore";
import SiteStore from "./SiteStore";
import OrganizationStore from "./OrganizationStore";

class ApplicationStore {
  initStores = () => {
    this.auth.init();
  };

  visits = new VisitsStore();
  site = new SiteStore();
  organization = new OrganizationStore();
  auth = new AuthStore();
}

export default new ApplicationStore();
