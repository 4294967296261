import { useEffect, useState } from "react";
import type { FC } from "react";
import { useRoutes } from "react-router-dom";
import { Box, Button, CssBaseline, ThemeProvider, Typography } from "@material-ui/core";
import { initializeI18n } from "./i18n";
import { useSettings } from "./contexts/SettingsContext";

import routes from "./routes";
import { createCustomTheme } from "./theme";
import useStore from "./hooks/useStore";
import Loader from "./components/atoms/Loader/Loader";
import { useAuth0 } from "@auth0/auth0-react";

import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-GB";
import { orgApi } from "./api/organization-admin/organization";
import { observer } from "mobx-react-lite";

const App: FC = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const store = useStore();

  const { isAuthenticated, user, isLoading, error, logout, getAccessTokenSilently } = useAuth0();

  // Step 1 Get access token - because all endpoint need access token
  const [isLoadingToken, setIsLoadingToken] = useState(false);

  const getToken = async () => {
    setIsLoadingToken(true);
    try {
      const token = await getAccessTokenSilently();

      store.auth.setToken(token);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingToken(false);
    }
  };

  // Step 2 Get sites, because all other endpoints need siteToken
  const [isLoadingSites, setIsLoadingSites] = useState<boolean>(false);

  const fetchOrgSites = async () => {
    setIsLoadingSites(true);

    try {
      const sites = await orgApi.fetchOrganizationSites();

      store.site.setOrganizationSites(sites);

      if (!store.site.siteToken) {
        store.site.setSiteToken(sites[0].token);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingSites(false);
    }
  };

  // Step 3 Fetch all destinations - need this for show all destinations in visit filters
  useEffect(() => {
    if (store.site.siteToken && isAuthenticated && store.auth.token) {
      store.visits.fetchDestinations(1, 100);
    }
  }, [store.site.siteToken, isAuthenticated, store.auth.token]);

  useEffect(() => {
    initializeI18n(settings.language);
  }, [settings]);

  useEffect(() => {
    if (isAuthenticated) {
      getToken().then(() => {
        store.organization.setActiveOrganizationToken(
          user["https://platform.signalos.io/active_org_id"]
        );
      });
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (store.organization.activeOrganizationToken && isAuthenticated) {
      fetchOrgSites();
    }
  }, [store.organization.activeOrganizationToken, isAuthenticated]);

  const theme = createCustomTheme({
    direction: settings.direction,
    theme: settings.theme,
  });

  const renderContent = () => {
    if (isLoading || isLoadingSites || isLoadingToken) {
      return (
        <Box
          sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}
        >
          <Loader />
        </Box>
      );
    }
    if (error) {
      return (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <Typography>Oops... {error.message}</Typography> <br />
          <Button variant="contained" onClick={() => logout({ returnTo: window.location.origin })}>
            Logout
          </Button>
        </Box>
      );
    } else {
      return content;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        locale={settings.language === "pl" ? pl : en}
      >
        <CssBaseline />
        {renderContent()}
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default observer(App);
