import { useState, useEffect, useMemo } from "react";
import type { FC, ElementType } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Drawer, List } from "@material-ui/core";
import { NavbarMenuItem } from "../../components/molecules/NavbarMenuItem";
import { getRoutes } from "src/constants/routes";

interface NavbarMenuProps {
  onClose: () => void;
  open: boolean;
}

interface Item {
  href?: string;
  external?: boolean;
  icon: ElementType;
  items?: Array<{ href: string; title: string }>;
  title: string;
}

export const NavbarMenu: FC<NavbarMenuProps> = (props) => {
  const { open, onClose } = props;
  const { pathname } = useLocation();
  const [openedItem, setOpenedItem] = useState<Item | null>(null);
  const [activeItem, setActiveItem] = useState<Item | null>(null);
  const [activeHref, setActiveHref] = useState("");

  const routes = useMemo(() => getRoutes(), []);

  const handleOpenItem = (item: Item): void => {
    if (openedItem === item) {
      setOpenedItem(null);
      return;
    }

    setOpenedItem(item);
  };

  useEffect(() => {
    routes.forEach((route) => {
      if (route.subRoutes) {
        for (let index = 0; index < route.subRoutes.length; index++) {
          const active = matchPath({ path: route.subRoutes[index].href, end: true }, pathname);

          if (active) {
            setActiveItem(route);
            setActiveHref(route.subRoutes[index].href);
            setOpenedItem(route);
            break;
          }
        }
      } else {
        const active = !!matchPath({ path: route.href, end: true }, pathname);

        if (active) {
          setActiveItem(route);
          setOpenedItem(route);
        }
      }
    });
  }, [pathname]);

  return (
    <Drawer
      anchor="top"
      onClose={onClose}
      open={open}
      transitionDuration={0}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: "#2B2F3C",
          color: "#B2B7C8",
          display: "flex",
          flexDirection: "column",
          top: 64,
          maxHeight: "calc(100% - 64px)",
          width: "100vw",
        },
      }}
    >
      <List>
        {activeItem &&
          routes.map((item) => (
            <NavbarMenuItem
              active={activeItem?.title === item.title}
              activeHref={activeHref}
              key={item.title}
              onClose={onClose}
              onOpenItem={() => handleOpenItem(item)}
              open={openedItem?.title === item.title}
              {...item}
            />
          ))}
      </List>
    </Drawer>
  );
};
