import { Suspense, lazy } from "react";
import type { DataRouteObject } from "react-router";
import { Navigate } from "react-router-dom";
import { Layout } from "./layout/MainLayout";

import { LoadingScreen } from "./components/LoadingScreen";

import AuthGuard from "./components/Guards/AuthGuard";

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

//Visit pages

const CreateVisit = Loadable(
  lazy(() =>
    import("./containers/visits/create").then((module) => ({
      default: module.CreateVisitContainer,
    }))
  )
);

const VisitContainer = Loadable(
  lazy(() =>
    import("./containers/visits/[visitId]").then((module) => ({
      default: module.VisitContainer,
    }))
  )
);

const VisitsStatisticContainer = Loadable(
  lazy(() =>
    import("./containers/visits/statistics").then((module) => ({
      default: module.VisitsStatisticContainer,
    }))
  )
);

//Visit pages
const Visitors = Loadable(
  lazy(() =>
    import("./containers/visitors").then((module) => ({ default: module.VisitsContainer }))
  )
);

//Destination pages
const Destinations = Loadable(
  lazy(() =>
    import("./containers/destinations").then((module) => ({
      default: module.DestinationsContainer,
    }))
  )
);

const CreateDestination = Loadable(
  lazy(() =>
    import("./containers/destinations/create").then((module) => ({
      default: module.CreateDestinationContainer,
    }))
  )
);

const Destination = Loadable(
  lazy(() =>
    import("./containers/destinations/[destinationId]").then((module) => ({
      default: module.DestinationContainer,
    }))
  )
);

//Hosts pages
const Hosts = Loadable(
  lazy(() => import("./containers/hosts").then((module) => ({ default: module.HostsContainer })))
);

const Host = Loadable(
  lazy(() =>
    import("./containers/hosts/[hostId]").then((module) => ({
      default: module.HostContainer,
    }))
  )
);

//Hosts pages
const BillingGroups = Loadable(
  lazy(() =>
    import("./containers/billingGroups").then((module) => ({
      default: module.BillingGroupsContainer,
    }))
  )
);

const BillingGroup = Loadable(
  lazy(() =>
    import("./containers/billingGroups/[billingGroupId]").then((module) => ({
      default: module.BillingGroupContainer,
    }))
  )
);
const CreateBillingGroup = Loadable(
  lazy(() =>
    import("./containers/billingGroups/createBillingGroup").then((module) => ({
      default: module.CreateBillingGroupContainer,
    }))
  )
);

//Hosts pages
const DestinationGroups = Loadable(
  lazy(() =>
    import("./containers/destinationGroups").then((module) => ({
      default: module.DestinationGroupsContainer,
    }))
  )
);

const DestinationGroup = Loadable(
  lazy(() =>
    import("./containers/destinationGroups/[destinationGroupId]").then((module) => ({
      default: module.DestinationGroupContainer,
    }))
  )
);

//ACS pages
const AccessControlProfiles = Loadable(
  lazy(() =>
    import("./containers/acp").then((module) => ({
      default: module.AccessControlProfilesContainer,
    }))
  )
);

// Alerts list
const AccessControlEvents = Loadable(
  lazy(() =>
    import("./containers/accessControlEvents").then((module) => ({
      default: module.AccessControlEventsContainer,
    }))
  )
);
//Organiation admins
const OrganizationAdmins = Loadable(
  lazy(() =>
    import("./containers/organizationAdmins").then((module) => ({
      default: module.OrganizationAdminsContainer,
    }))
  )
);

//Settings
const Settings = Loadable(
  lazy(() =>
    import("./containers/settings").then((module) => ({
      default: module.SettingsContainer,
    }))
  )
);
const Gdpr = Loadable(
  lazy(() =>
    import("./containers/settings/gdpr").then((module) => ({
      default: module.GdprContainer,
    }))
  )
);
const DefaultSettings = Loadable(
  lazy(() =>
    import("./containers/settings/defaultSettings").then((module) => ({
      default: module.DefaultSettingsContainer,
    }))
  )
);
const Logo = Loadable(
  lazy(() =>
    import("./containers/settings/logo").then((module) => ({
      default: module.LogoContainer,
    }))
  )
);

//Admins
const Admins = Loadable(
  lazy(() => import("./containers/admins").then((module) => ({ default: module.AdminsContainer })))
);

// Not found pages
const NotFound = Loadable(
  lazy(() => import("./containers/NotFound").then((module) => ({ default: module.NotFound })))
);

const routes: DataRouteObject[] = [
  {
    path: "visits",
    id: "visits",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "visits",
        path: "/visits",
        element: <Visitors />,
      },
      {
        id: "create-visit",
        path: "/visits/create",
        element: <CreateVisit />,
      },
      {
        id: "visit",
        path: "/visits/:visitId",
        element: <VisitContainer />,
      },
      {
        id: "statistic",
        path: "/visits/statistic",
        element: <VisitsStatisticContainer />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/visits" replace />,
    id: "visits",
  },
  {
    path: "hosts",
    id: "hosts",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/hosts",
        element: <Hosts />,
        id: "hosts",
      },
      {
        path: "/hosts/:hostId",
        element: <Host />,
        id: "host",
      },
    ],
  },
  {
    path: "billing-groups",
    id: "billing-groups",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/billing-groups",
        element: <BillingGroups />,
        id: "billing-groups",
      },
      {
        path: "/billing-groups/:billingGroupId",
        element: <BillingGroup />,
        id: "billing-group",
      },
      {
        path: "/billing-groups/create",
        id: "create-billing-group",
        element: <CreateBillingGroup />,
      },
      {
        path: "/billing-groups/edit",
        element: <CreateBillingGroup />,
        id: "edit-billing-group",
      },
    ],
  },
  {
    path: "destination-groups",
    id: "destination-groups",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/destination-groups",
        id: "destination-groups",
        element: <DestinationGroups />,
      },
      {
        path: "/destination-groups/:destinationGroupId",
        id: "destination-group",
        element: <DestinationGroup />,
      },
    ],
  },
  {
    path: "access-control-profiles",
    id: "access-control-profiles",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/access-control-profiles",
        id: "access-control-profiles",
        element: <AccessControlProfiles />,
      },
    ],
  },
  {
    path: "destinations",
    id: "destinations",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/destinations",
        id: "destinations",
        element: <Destinations />,
      },
      {
        path: "/destinations/create",
        id: "create-destination",
        element: <CreateDestination />,
      },
      {
        id: "destination",
        path: "/destinations/:destinationId",
        element: <Destination />,
      },
    ],
  },
  {
    path: "organization-admins",
    id: "organization-admins",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/organization-admins",
        id: "organization-admins",
        element: <OrganizationAdmins />,
      },
    ],
  },

  {
    id: "settings-container",
    path: "settings",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        id: "settings",
        path: "/settings",
        element: <Settings />,
        children: [
          {
            id: "settings-gdpr",
            path: "/settings/gdpr",
            element: <Gdpr />,
          },
          {
            id: "settings-default",
            path: "/settings/default-settings",
            element: <DefaultSettings />,
          },
          {
            id: "settings-logo",
            path: "/settings/logo",
            element: <Logo />,
          },
        ],
      },
    ],
  },
  {
    path: "admins",
    id: "admins",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/admins",
        id: "admins",
        element: <Admins />,
      },
    ],
  },
  {
    path: "access-control-events",
    id: "access-control-events",
    element: (
      <AuthGuard>
        <Layout />
      </AuthGuard>
    ),
    children: [
      {
        path: "/access-control-events",
        id: "access-control-events",
        element: <AccessControlEvents />,
      },
    ],
  },
  {
    path: "*",
    id: "not-found",
    element: <NotFound />,
  },
];

export default routes;
